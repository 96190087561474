<template>
  <div class="custom-header" :style="headerStyles">
    <img v-if="icon" :src="icon" alt="logo" />
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'CustomHeader',
  props: {
    color: {
      type: String,
      default: '#fff',
    },
    background: {
      type: String,
      default: '#fff',
    },
    borderColor: {
      type: String,
      default: '#88da69',
    },
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    headerStyles() {
      return {
        color: this.color,
        background: this.background,
        borderColor: this.borderColor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-header {
  height: 40px;
  border-bottom: 2px solid #88da69;
  font-family: 'Circular-Std-Medium';
  line-height: 40px;
  position: relative;
  overflow: hidden;
  border-radius: 3px 3px 0 0;
  img {
    width: 28px;
    margin-left: 11px;
    margin-top: 4px;
    float: left;
  }
  span {
    float: none;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 24px;
    transform: translate(-50%, -50%);
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      width: 100%;
    }
  }
}
</style>
